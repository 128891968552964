<template>
  <v-app-bar
    id="app-bar"
    app
    absolute
    color="#1EA9E0"
    flat
    class="v-bar--underline"
  >
    <v-app-bar-nav-icon>
      <v-img
        max-height="60"
        max-width="50"
        src="@/assets/images/logo.jpg"
      ></v-img>
    </v-app-bar-nav-icon>
    <v-toolbar-title>ORIGIN WAREHOUSE SERVICES</v-toolbar-title>

    <v-spacer></v-spacer>

    <template v-if="isAuth">
      <shipping-menu />

      <admin-menu />

      <vertical-divider />

      <user-menu />
    </template>
    <template v-else>
      <v-btn to="/login" color="blue">
        <v-icon left color="white"> mdi-clipboard-account </v-icon>
        Login
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    ShippingMenu: () => import("@/components/core/menu/ShippingMenu"),
    AdminMenu: () => import("@/components/core/menu/AdminMenu"),
    UserMenu: () => import("@/components/core/menu/UserMenu"),
    VerticalDivider: () => import("@/components/core/menu/VerticalDivider"),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    prevRoute: null,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters(["drawer", "isAuth"]),
  },
};
</script>

<style lang="sass">
#default-app-bar
  .v-btn--active.v-btn--app:not(:hover):not(:focus)
    &::before
      opacity: 0
</style>
